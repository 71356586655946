import { Tab, Tabs } from "@material-ui/core"
import React, { useState } from "react"
import "./style.scss"
import CustomAccordion from "../../Accordion"
import { HandleRenderCVInfor } from "../RenderTree"
import isEmpty from "lodash/isEmpty"

function ParseResult({ data }) {
  const [isParseJson, setIsParseJson] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)

  if (!data) {
    return null
  }
  const { img_base64, ...restData } = data
  const parseAccodion = Object.keys(restData)
    .map(key => {
      let filteredArray = restData[key]
      if (Array.isArray(restData[key])) {
        filteredArray = restData[key].filter(e => !isEmpty(e))
      }
      if (isEmpty(filteredArray)) return null
      return {
        title: key,
        description: <HandleRenderCVInfor data={filteredArray} />,
      }
    })
    .filter(e => !!e)

  return (
    <div className="parse-result-wrapper">
      <div className="horizontal-btn">
        <button
          onClick={() => {
            setIsParseJson(false)
          }}
        >
          Parsed Result
        </button>
        <button
          onClick={() => {
            setIsParseJson(true)
          }}
        >
          Response JSON
        </button>
      </div>
      <div className="result">
        {img_base64 && (
          <div className="display-avatar center">
            <img
              src={`data:image/png;base64, ${img_base64}`}
              alt="CV avatar"
              className="avatar"
            />
          </div>
        )}
        {isParseJson && (
          <Tabs
            TabIndicatorProps={{
              style: { backgroundColor: "#123a67", height: "3px" },
            }}
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={(e, newValue) => setCurrentTab(newValue)}
            aria-label="basic tabs example"
          >
            {["Raw JSON", "Beautify JSON"].map((e, i) => (
              <Tab label={e} key={i} value={i} />
            ))}
          </Tabs>
        )}
        <div className="display-result">
          {isParseJson ? (
            <pre>{JSON.stringify(restData, null, currentTab === 1 && 4)}</pre>
          ) : (
            <CustomAccordion data={parseAccodion} />
          )}
        </div>
      </div>
    </div>
  )
}

export default ParseResult
