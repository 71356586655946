import React, { useLayoutEffect, useState } from "react"
import Img from "gatsby-image"
import "./style.scss"
import LinearProgress from "@material-ui/core/LinearProgress"

function mutateClass(element, className, isAdd) {
  var list = element.className.split(" ")
  if (list.indexOf(className) !== -1) {
    if (!isAdd) delete list[list.indexOf(className)]
  } else {
    if (isAdd) list[list.length] = className
  }
  element.className = list.join(" ")
}

const a = [
  "drag",
  "dragstart",
  "dragend",
  "dragover",
  "dragenter",
  "dragleave",
  "drop",
]
const b = ["dragover", "dragenter"]
const c = ["dragleave", "dragend", "drop"]

const EncryptError = "Can't open file protected with password"

export const FormUploadCV = ({
  uploadIcon,
  isParsed,
  setIsParsed,
  setResult,
  handleSubmit,
  isLoading,
  errorParser,
  setError
}) => {
  const [file, setFile] = useState()
  const [isEncrypted, setIsEncrypted] = useState(false)
  const onSubmit = e => {
    e.preventDefault()
    setIsParsed(true)
    handleSubmit(file[0])
  }

  const acceptType = [
    "application/pdf",
    "image/png",
    "image/jpg",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ]

  const checkFileEncrypt = (file) => {
    if (file && file?.type === 'application/pdf') {
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = function () {
        var files = new Blob([reader.result], { type: 'application/pdf' })
        files.text().then(x => {
          if (x.includes("Encrypt")) {
            setIsEncrypted(true)
          }
        })
      }
    }
  }

  const handleChange = e => {
    reselect()
    const fileSelect = e.target.files
    setFile(fileSelect)

    checkFileEncrypt(fileSelect[0])
  }

  const reselect = () => {
    setIsParsed(false)
    setResult(null)
    setIsEncrypted(false)
    setError(null)
  }

  const reset = () => {
    setFile(undefined)
    reselect()
    document.getElementById("file").value = ""
  }

  useLayoutEffect(() => {
    const uploadSection = document.getElementById("upload")

    a.forEach(e =>
      uploadSection.addEventListener(e, evt => {
        evt.preventDefault()
        evt.stopPropagation()
      })
    )
    b.forEach(e =>
      uploadSection.addEventListener(e, () => {
        mutateClass(uploadSection, "is-dragover", true)
      })
    )
    c.forEach(e =>
      uploadSection.addEventListener(e, () => {
        mutateClass(uploadSection, "is-dragover", false)
      })
    )
    uploadSection.addEventListener("drop", e => {
      reselect()
      checkFileEncrypt(e.dataTransfer.files[0])
      setFile(e.dataTransfer.files)
    })

    return () => {
      a.forEach(e => uploadSection.removeEventListener(e, () => {}))
    }
  }, [])

  useLayoutEffect(() => {
    const parseView = document.getElementById("parse")
    isParsed &&
      !isLoading &&
        parseView &&
        parseView.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        })
  }, [isParsed, isLoading])

  const renderResult = () => {
    if (isEncrypted) {
      return <strong>{EncryptError}</strong>
    } else if (!isParsed) {
      return (
        <button
          disabled={
            file?.length !== 1
            || !acceptType.includes(file?.[0].type)
          }
          type="submit"
          onClick={onSubmit}
        >
          Parse CV
        </button>
      )
    } else if (isLoading) {
      return <LinearProgress className="progress-line-bar" />
    } else if (errorParser) {
      return <strong>{errorParser}</strong>
    } else {
      return (
        <>
          <button id="parse" type="button" className="un-use-btn">
            CV Parsing Completed!
          </button>
          <br />
          <p>
            <strong>See the output below</strong>
          </p>
        </>
      )
    }
  }

  return (
    <form className="center">
      <div className="wrap-upload">
        <div id="upload" className="upload-section">
          <Img fixed={uploadIcon} alt="CV parsing - browse"/>
          <p>
            Drag &#38; Drop one (1) resume here or{" "}
            <input
              onChange={handleChange}
              type="file"
              name="files[]"
              id="file"
              accept="application/pdf, image/png, image/jpg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              disabled={isEncrypted || isParsed || errorParser}
            />
            <label className="browse" htmlFor="file">
              <strong>Browse</strong>
            </label>
            {file?.length > 0 &&
            (file.length > 1 ? (
              <>
                <p>Please select one (1) file only</p>
              </>
            ) : !acceptType.includes(file[0].type) ? (
              <p>
                Please select <strong>PDF</strong> or <strong>DOC</strong> or <strong>DOCX</strong> or <strong>PNG</strong> or <strong>JPG</strong> file.
              </p>
            ) : (
              <>
                <p>
                  <strong className="truncate">
                    {file[0].name.length > 30
                      ? `${file[0].name.slice(0, 30)}...`
                      : file[0].name}
                  </strong>{" "}
                  file selected
                </p>
                <br />
                <span style={{ visibility: (isEncrypted || isParsed || errorParser) && 'hidden' }} className="start-over" onClick={reset}>
                  Start Over?
                </span>
              </>
            ))}
          </p>
        </div>
        {(isEncrypted || isParsed || errorParser) && (
          <div className="reset-upload">
            <span className="start-over" onClick={reset}>
              Start Over?
            </span>
          </div>
        )}
      </div>
      {renderResult()}
    </form>
  )
}
