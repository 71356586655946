import React from "react"
import Spinner from 'react-bootstrap/Spinner';
import './Spinner.scss'

const SpinnerView = ({ animation = "border", ...props }) => {
  return (
    <div className="overlay-spinner">
      <Spinner animation={animation} {...props} />
    </div>
  )
}

export default SpinnerView