import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import axios from "axios"
import SEO from "/src/components/seo"
import Banner from "../../../components/Banner/Banner"
import Layout from "../../../components/layout"
import { FormUploadCV } from "../../../components/CVParsing/FormUploadCV"
import ParseResult from "../../../components/CVParsing/ParseResult"
import GuidParser from "../../../components/GuidParser/GuidParser"
import SpinnerView from "../../../components/Spinner/Spinner"

const bannerImg = `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/HomePage/home_page_slider.webp`

const bannerData = {
  title: ["Neurond's CV Parser"],
  content: ["The Enterprise Grade CV Parser API"],
}

const ErrorParsing = "CV Parsing failed!"

const CVParsingDemo = ({ data }) => {
  const { t } = useTranslation()
  const [isParsed, setIsParsed] = useState(false)
  const [result, setResult] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isLoadingApi, setIsLoadingApi] = useState(true)

  const toggleModal = () => {
    setShowModal(!showModal)
    const scrollDiv = document.getElementById("form-upload").offsetTop
    window.scrollTo({ top: scrollDiv - 300, behavior: 'smooth'})
  }

  const handleSubmit = file => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append("file", file)
    axios({
      method: "post",
      url:
        "https://cvparserapp.proudriver-a98eed0c.eastasia.azurecontainerapps.io/upload/",
      headers: {
        // "Ocp-Apim-Subscription-Key": "bde41fa91570400cafa8f3dbad877214",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then(({ data }) => {
        setResult(data.result.data)
        setIsParsed(true)
        setIsLoading(false)
      })
      .catch(err => {
        setResult(null)
        setError(err?.data?.message || ErrorParsing)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    axios.get("https://cvparserapp.proudriver-a98eed0c.eastasia.azurecontainerapps.io/health")
      .then(function (response) {
        setIsLoadingApi(false)
      })
      .catch(function (error) {
        setIsLoadingApi(false)
      })

    setTimeout(() => {
      setIsLoadingApi(prevState => {
        if (prevState) {
          setShowModal(true)
          return false
        }
        return false
      })
    }, 3000)
  }, [])

  return (
    <Layout>
      <SEO title={t("cv_parsing")} />
      {isLoadingApi && <SpinnerView />}
      <GuidParser showModal={showModal} hide={toggleModal} />
      <div className="margin-bot">
        <Banner item={bannerData} backgroundImage={bannerImg} />
        <div className="ellipse" id="form-upload" />
      </div>
      <div>
        <section>
          <FormUploadCV
            uploadIcon={data.file.childImageSharp.fixed}
            isParsed={isParsed}
            setIsParsed={setIsParsed}
            setResult={setResult}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            errorParser={error}
            setError={setError}
          />
        </section>
        {isParsed && !isLoading && !error && (
          <section>
            <ParseResult data={result} />
          </section>
        )}
      </div>
    </Layout>
  )
}

export default CVParsingDemo

export const query = graphql`
  query {
    file(relativePath: { eq: "icon/upload-icon.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 150, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
