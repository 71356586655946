import React from "react"
import "./style.scss"
import isEmpty from "lodash/isEmpty"

export const HandleRenderCVInfor = ({ data }) => {
  if (Array.isArray(data)) {
    if (typeof data[0] === "string")
      return <RenderContent data={data} isArrayString={true} />
    return (
      <>
        {data.map((item, index) => (
          <RenderContent key={index} data={item} />
        ))}
      </>
    )
  }
  return <RenderContent data={data} />
}

const RenderContent = ({ data, noHeader, isArrayString = false }) => {
  Object.keys(data).forEach(key => {
    let filteredArray = data[key]
    if (Array.isArray(data[key])) {
      filteredArray = data[key].filter(e => !isEmpty(e))
    }
    if (isEmpty(filteredArray)) {
      data[key] = "N/A"
    }
  })
  const parseData = isArrayString
    ? { content: data }
    : {
        header:
          Array.isArray(Object.values(data)[0]) &&
          Object.values(data)[0].length > 0
            ? Object.values(data)[0][0]
            : Object.values(data)[0],
        content: Object.keys(data).map(key => ({
          key,
          value:
            Array.isArray(data[key]) && data[key].length > 1
              ? data[key].map(e => `- ${e}`).join("\n")
              : data[key],
        })),
      }

  return (
    <>
      <div className="detail-card">
        {!isArrayString && !noHeader && (
          <div className="detail-header">{parseData.header}</div>
        )}
        <div className="detail-content">
          {parseData.content.map(e => (
            <div className="flex">
              {isArrayString ? (
                <span className="value">{e}</span>
              ) : (
                <>
                  <span className="key">{`${e.key}: `}</span>
                  {typeof e.value[0] === "object" ? (
                    <RenderContent noHeader data={e.value[0]} />
                  ) : (
                    <span className="value">{e.value}</span>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
