import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons"
import "bootstrap/dist/css/bootstrap.min.css"
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'animate.css';
import "./GuidParser.scss"

import Prepare from "../../assets/img/ParserGuid/prepare-cv.png"
import Upload from "../../assets/img/ParserGuid/upload.png"
import Result from "../../assets/img/ParserGuid/result.png"
import Send from "../../assets/img/ParserGuid/send.png"

export default ({ showModal, hide }) => {
  const [currentIndexSlide, setCurrentIndexSlide] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setCurrentIndexSlide(1)
    }, 500)
  }, [])

  const CustomPrevArrow = (arrowProps) => {
    const { className, style, onClick } = arrowProps;
    return (
      <FontAwesomeIcon
        icon={faCaretLeft}
        className={className}
        style={{ ...style, zIndex: 99, display: "block", color: 'black', width: '50px', height: '50px' }}
        onClick={onClick}
      />
    )
  }

  const CustomNextArrow = (arrowProps) => {
    const { className, style, onClick } = arrowProps;
    return (
      <FontAwesomeIcon
        icon={faCaretRight}
        className={className}
        style={{ ...style, zIndex: 99, display: "block", color: 'black', width: '50px', height: '50px' }}
        onClick={onClick}
      />
    )
  }

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false
        }
      },
    ],
    className: 'guid-container',
    afterChange: currentSlide => setCurrentIndexSlide(currentSlide + 1),
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />
  }

  return (
    <Modal
      className="modal modal-guid"
      backdrop="static"
      show={showModal}
      onHide={hide}
    >
      <Slider {...settings}>
        <div className="step-guid">
          {currentIndexSlide === 1 && (
            <>
              <p className="guid-title animate__animated animate__fadeInUp">How to use DrParser ?</p>
              <p className="guid-content animate__animated animate__fadeInUp">Click next to view more</p>
              {/*<img className="animate__animated animate__fadeInUp" src={Prepare} alt='Prepare' />*/}
            </>
          )}
        </div>
        <div className="step-guid">
          {currentIndexSlide === 2 && (
            <>
              <p className="guid-title animate__animated animate__fadeInUp">1. Prepare your CV</p>
              <img className="animate__animated animate__fadeInUp" src={Prepare} alt='Prepare' />
            </>
          )}
        </div>
        <div className="step-guid">
          {currentIndexSlide === 3 && (
            <>
              <p className="guid-title animate__animated animate__fadeInUp">2. Upload/Browse your CV</p>
              <img className="animate__animated animate__fadeInUp" src={Upload} alt='Upload' />
            </>
          )}
        </div>
        <div className="step-guid">
          {currentIndexSlide === 4 && (
            <>
              <p className="guid-title step-3 animate__animated animate__fadeInUp">3. See the result</p>
              <img className="animate__animated animate__fadeInUp" src={Result} alt='Result' />
              <div
                className="close-guide animate__animated animate__fadeInUp animate__delay-1s animate__slow"
                onClick={hide}
              >
                START YOUR JOURNEY NOW !
                <img src={Send} className="close-guide__icon" alt="send"/>
              </div>
            </>
          )}
        </div>
      </Slider>
    </Modal>
  );
}